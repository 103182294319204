<template>
  <div>
    <!-- 头部 -->
    <section ref="refStop" class="section panel servicehome_header">
      <div class="col-lg-12 container_max">
        <header class="header_title">
          <div class="T48px_Regular colorfff">
            <span class="color1D73FF">{{ $t("brand.title1") }}</span>
            <span>{{ $t("brand.title2") }}</span>
          </div>

          <div class="T18px_Regular colorfff header_title_tips">
           {{ $t("brand.header_title_tips") }}
          </div>
        </header>
      </div>
    </section>

    <!-- 步骤 -->
    <section ref="refStop" class="section step_box">
      <div class="flex-w step_box_max" style="align-items: flex-start">
        <div
          class="step_son_item flex-c panel_stop_box"
          v-for="(item, index) in stepArrList"
          :key="index"
        >
          <img class="step_son_max_logo" :src="item.url" alt="" />
          <span class="ranking T48px_Regular_ya">
            {{ index + 1 }}
          </span>
          <div class="flex-w step_son_item_title">
            <img class="step_son_icons" :src="item.icons" alt="" />
            <img class="step_son_icon" :src="item.icon" alt="" />
            <h1 class="flex-w T48px_Regular step_son_item_title_text">
              <span class="">{{ item.name }}</span>
              <span class="colorfff">{{ item.names }}</span>
            </h1>
          </div>
          <div class="T16px_Regular step_son_text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section panel project_summarize_box">
      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

function disableScroll() {
  //   document.body.style.overflow = "hidden"; // 禁用页面滚动
}

function enableScroll() {
  //   document.body.style.overflow = ""; // 恢复页面滚动
}

export default {
  data() {
    return {
      stepArrList: [
        {
          url:"https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max1.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max1.png",
          icons: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max1s.png",
          name: this.$t("brand.stepArrList1_name"),
          names: this.$t("brand.stepArrList1_names"),
          text: this.$t("brand.stepArrList1_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max2.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max2.png",
          icons: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max2s.png",
           name: this.$t("brand.stepArrList2_name"),
          names: this.$t("brand.stepArrList2_names"),
          text: this.$t("brand.stepArrList2_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max3.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max3.png",
          icons: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max3s.png",
          name: this.$t("brand.stepArrList3_name"),
          names: this.$t("brand.stepArrList3_names"),
          text: this.$t("brand.stepArrList3_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max4.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max4.png",
          icons: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max4s.png",
          name: this.$t("brand.stepArrList4_name"),
          names: this.$t("brand.stepArrListy4_names"),
          text: this.$t("brand.stepArrList4_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max5.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max5.png",
          icons: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max5s.png",
           name: this.$t("brand.stepArrList5_name"),
          names: this.$t("brand.stepArrList5_names"),
          text: this.$t("brand.stepArrList5_text"),
        }
      ],
    };
  },
  mounted() {
    this.stopSlideInit();
  },
  methods: {
    /**
     *  步骤滚动动画
     */
    stopSlideInit() {
      var texts = gsap.utils.toArray(".panel_stop_box");

      // 默认选中第一个板块
      texts[0].classList.add("act");

      texts.forEach((text, i) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: "section.step_box",
            scroller: this.$refs.scroller,
            start: () => "top -" + window.innerHeight * 0.3 * i,
            end: () => "+=" + window.innerHeight * 0.3,
            scrub: 1.5,
            snap: {
              snapTo: 1 / (texts.length - 1), // 自动捕捉到下一个区域
              duration: 0.5, // 动画的持续时间
              ease: "power2.inOut",
            },
            onEnter: () => {
              disableScroll(); // 禁用滚动
              text.classList.add("act");
              setTimeout(() => {
                enableScroll();
              }, 800);
            },
            onLeave: () => {
              if (i === texts.length - 1) {
                disableScroll(); // 禁用滚动
                text.classList.add("act");
                setTimeout(() => {
                  enableScroll();
                }, 800);
              } else {
                text.classList.remove("act");
              }
            },
            onEnterBack: () => {
              disableScroll(); // 禁用滚动
              text.classList.add("act");
              setTimeout(() => {
                enableScroll();
              }, 800);
            },
            onLeaveBack: () => {
              // text.classList.remove("act"); // 再次离开时移除 act 类
              if (i === 0) {
                disableScroll(); // 禁用滚动
                text.classList.add("act");
                setTimeout(() => {
                  enableScroll();
                }, 800);
              } else {
                text.classList.remove("act");
              }
            },
          },
        });
      });
      texts[0].classList.add("act");
      ScrollTrigger.create({
        trigger: "section.step_box",
        scroller: this.$refs.scroller,
        scrub: 1.5, // 禁用自动平滑过渡

        pin: true,
        start: "top top",
        end: () => "+=" + (texts.length + 1) * window.innerHeight * 0.3,
        onLeaveBack: () => {
          texts[0].classList.add("act"); // 确保在回到顶部时，第一个元素始终激活
        },
        // onLeave: () => {
        //     texts[texts.length - 1].classList.add("act"); // 滑动到底部时激活最后一个板块
        // }
      });

      // 延迟刷新，确保触发器的正确性
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 1500);
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.servicehome_header {
  // min-height: 100vh;
  background: #15171d;
  padding-top: 5.6875rem;
  padding-bottom: 0;

  .container_max {
    width: 100%;
    padding: 0 5.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      width: 37.5rem;
      margin-bottom: 1.6563rem;

      .header_title_tips {
        line-height: 1.3125rem;
        letter-spacing: 0.0313rem;
      }
    }
  }
}

.step_box {
  padding: 0;
  background: #15171d;
  height: 30.6875rem;

  .step_box_max {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .step_son_item {
    // min-width: 4.5438rem;
    width: calc((100vw - 40.3125rem) / 4);
    position: relative;
    min-height: 24.5313rem;
    top: 0;
    // opacity: 0.5;
    transition: all 0.8s ease;
    transform-origin: left; /* 从左侧开始扩展 */
    /* 动画从右侧展开 */
    overflow: hidden;

    .step_son_icons {
      width: 1.7188rem;
      height: 1.7188rem;
      opacity: 1;
      display: block;
      transition: all 0.8s ease;
    }

    .step_son_icon {
      width: 1.7188rem;
      height: 1.7188rem;
      opacity: 0;
      display: none;
      transition: all 0.8s ease;
    }
    .ranking {
      position: absolute;
      color: #fff;
      top: 16.1563rem;
      right: 0.75rem;
    }

    .step_son_max_logo {
      width: 100%;
      height: 19.4375rem;
      margin-bottom: 1.375rem;
    }

    .step_son_item_title {
      border-bottom: 0.0313rem solid rgba(71, 71, 71, 0.63);
      padding-bottom: 0.6563rem;
      // border-image:;

      .step_son_item_title_text {
        display: none;
      }
    }

    .step_son_text {
      width: 3.3125rem;
      height: 3.9375rem;
      overflow: hidden;
      line-height: 1.3125rem;
      color: #404146;
      margin-top: 0.4688rem;
      opacity: 0;
      display: none;
      transition: all 0.8s ease;
    }

    &::after {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      content: " ";
      width: 100%;
      height: 19.4375rem;
      background: rgba(0, 0, 0, 0.71);
      z-index: 2;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }

  .act {
    width: 40.3125rem;
    color: #1d73ff;
    position: relative;
    min-height: 24.5313rem;
    top: 0;
    opacity: 1;
    /* 激活时透明度变为完全不透明 */
    transition: width 0.8s ease, opacity 0.8s ease !important;
    transform-origin: left; /* 从左侧开始扩展 */
    overflow: hidden;

    .ranking {
      display: none;
    }

    .step_son_icons {
      opacity: 0;
      display: none;
    }

    .step_son_icon {
      display: block;
      opacity: 1;
    }
    .step_son_text {
      opacity: 1;
      display: block;
      transition: all 0.8s ease;
    }

    .step_son_max_logo {
       width: 40.3125rem;
      height: 19.4375rem;
    }

    .step_son_item_title {
      border-bottom: none;
      margin-left: 5.9375rem;

      .step_son_item_title_text {
        margin-left: 0.875rem;
        display: block;
      }
    }

    .step_son_text {
      margin-left: 5.9375rem;
      width: 19.9375rem;
      min-height: 3.9375rem;
      color: #ffffff;
    }

    &::after {
      // background: transparent;
      opacity: 0;
      transition: all 0.5s;
    }
  }

  @keyframes growRight {
    from {
      width: 4.8438rem;
    }

    to {
      width: 30.4063rem;
    }
  }

  // .arr_left_box {
  //     margin-left: auto;
  //     margin-right: 5.9375rem;
  //     margin-top: 1.7813rem;
  //     width: 1.8438rem;
  //     height: 1.8438rem;
  //     border: 0.0313rem solid #ffffff;
  //     border-radius: 50%;

  //     .arr_left_icon {
  //         width: 0.25rem;
  //         height: 0.4375rem;
  //     }
  // }
}

.project_summarize_box {
  background: #15171d;
  padding: 0;
  padding-bottom: 4.6875rem;
  // padding-top: 2.75rem;

  .customized_box {
    background: #15171d;
    margin: 0 5.875rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }
}
</style>
